import React, { useEffect } from 'react';

import {
  GBModalWrapper,
  GBTypography,
} from '@leapfinance/geebee-component-library';
import InfoOutlined from '@mui/icons-material/InfoOutlined';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import { differenceInDays, format } from 'date-fns';

import { useAppSelector } from '@/app/hooks';
import { MODAL_TYPES } from '@/components/Modals/GlobalModal';
import useModal from '@/components/Modals/useModal';
import { selectUserDetails } from '@/features/user/userSlice';
import { localStorageKeys } from '@/utils/GBLocalStorageUtil';

const STORAGE_KEY = localStorageKeys.COMMISSION_STRUCTURE_MODAL;
const modalExpiryDate = new Date(`09-10-2024`);
const numberOfTimesToShow = 3;

function useCommissionStructureModal() {
  const { showModal, hideModal } = useModal();
  const profileData = useAppSelector((state) => selectUserDetails(state));

  const getDatesModalWasShown = () => {
    try {
      const datesModalWasAlreadyShownString =
        localStorage.getItem(STORAGE_KEY) ?? `[]`;
      const dates = JSON.parse(datesModalWasAlreadyShownString);
      return dates;
    } catch (error) {
      return [];
    }
  };

  const addTodaysDate = () => {
    try {
      const dates = getDatesModalWasShown();
      const currentDate = format(new Date(), `dd-MM-yyyy`);
      dates.push(currentDate);
      localStorage.setItem(
        STORAGE_KEY,
        JSON.stringify(Array.from(new Set(dates))),
      );
    } catch (error) {}
  };

  useEffect(() => {
    if (
      profileData?.isCommissionStructureVisible &&
      differenceInDays(new Date(), modalExpiryDate) < 0
    ) {
      //show Modal
      try {
        const dates = getDatesModalWasShown();
        if (dates?.length >= numberOfTimesToShow) return;
        const currentDate = format(new Date(), `dd-MM-yyyy`);
        if (dates.includes(currentDate)) {
          return; //Modal already shown
        } else {
          //showModal
          showModal({
            modalType: MODAL_TYPES.NO_RESULTS,
            modalProps: {
              body: (
                // [TODO]: Refactor the GB Modal to be more scalable
                <div className="[&>div>div>div:first-child]:h-auto h-full w-full flex items-center justify-center">
                  <GBModalWrapper
                    headerProps={{
                      title: (
                        <div className="flex flex-col">
                          <GBTypography variant="sh1">
                            Commission Structure
                          </GBTypography>
                          <GBTypography variant="caption2" className="italic">
                            {` `}
                            %age on University Payout to GB
                          </GBTypography>
                        </div>
                      ),
                      onClose: () => {
                        addTodaysDate();
                        hideModal();
                      },
                    }}
                    variant="small"
                  >
                    <div className="w-full px-4 py-3 gap-4 flex flex-col">
                      <TableContainer className=" rounded border-gbTokens-grey-A500 border">
                        <Table className=" ">
                          <TableHead className="bg-gbTokens-primary-100 [&>tr>th]:border-gbTokens-grey-A500">
                            <TableRow>
                              <TableCell className="border-r border-gbTokens-grey-A500">
                                <GBTypography variant="caption1Semibold">
                                  Number of visa done
                                </GBTypography>
                              </TableCell>
                              <TableCell>
                                <GBTypography variant="caption1Semibold">
                                  Commission Rate
                                </GBTypography>
                              </TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody className="[&>tr>td]:border-gbTokens-grey-A500 ">
                            <TableRow>
                              <TableCell>
                                <GBTypography variant="body4">
                                  Upto 3
                                </GBTypography>
                              </TableCell>
                              <TableCell>
                                <GBTypography variant="body4">75%</GBTypography>
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell>
                                <GBTypography variant="body4">
                                  4th - 5th
                                </GBTypography>
                              </TableCell>
                              <TableCell>
                                <GBTypography variant="body4">80%</GBTypography>
                              </TableCell>
                            </TableRow>
                            <TableRow className="[&>td]:border-b-0">
                              <TableCell>
                                <GBTypography variant="body4">
                                  6th Enrollment and Onwards
                                </GBTypography>
                              </TableCell>
                              <TableCell>
                                <GBTypography variant="body4">85%</GBTypography>
                              </TableCell>
                            </TableRow>
                          </TableBody>
                        </Table>
                      </TableContainer>
                      <div className="p-1 flex items-center gap-1 bg-gbTokens-warning-100 rounded text-gbTokens-warning-500 border-gbTokens-warning-500 border">
                        <InfoOutlined className="text-sm" />
                        <GBTypography variant="caption1">
                          This is only applicable for Jan, Feb and Mar 2025
                          intakes
                        </GBTypography>
                      </div>
                    </div>
                  </GBModalWrapper>
                </div>
              ),
            },
          });
        }
      } catch (error) {}
    }
  }, [profileData?.isCommissionStructureVisible]);
}

export default useCommissionStructureModal;
