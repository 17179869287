import createSagaMiddleware from '@redux-saga/core';
import { configureStore } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/query';

import mockTestSaga from '@/components/IELTSPrep/Mocktest/MockTest/redux/MockTestSaga';
import mockTestSlice from '@/components/IELTSPrep/Mocktest/MockTest/redux/mockTestSlice';
import applicationSlice from '@/features/application/applicationSlice';
import confirmDialogSlice from '@/features/confirmDialog/confirmDialogSlice';
import { cfStateSlice } from '@/features/courseFinder/cfStateSlice';
import { courseFinderSlice } from '@/features/courseFinder/courseFinderSlice';
import eventsSlice from '@/features/events/eventsSlice';
import mappingSlice from '@/features/mapping/mappingSlice';
import modalsSlice from '@/features/modals/modalsSlice';
import paymentSlice from '@/features/payment/paymentSlice';
import joyRideSlice from '@/features/react-joyride-product-tour/reactJoyrideProductTour';
import sharedListSlice from '@/features/sharedList/sharedListSlice';
import studentProfileSlice from '@/features/student/studentProfileSlice';
import uploadDocuments from '@/features/uploadDocuments/uploadDocuments';
import userSlice from '@/features/user/userSlice';
import { authBaseApi } from '@/services/base/auth';
import { bookmarkListBaseApi } from '@/services/base/bookmarkListRoute';
import { cmsBaseApiWithTags } from '@/services/base/cmsRouteApi';
import { documentDownloadBaseApi } from '@/services/base/documentDownload';
import { documentUploadBaseApi } from '@/services/base/documentUpload';
import { geebeeRootBaseApiWithTags } from '@/services/base/geebeeRoute';
import { onboardingFormBaseApi } from '@/services/base/onboardingFormRoute';
import { seoRootBaseApiWithTags } from '@/services/base/seoRoute';
import { studentDashboardApiWithTags } from '@/services/base/studentDashboard';
import { courseFinderApi } from '@/services/courseFinder';
import { rewardsBaseApi } from '@/services/reward';
import { applicationApi, studentApi } from '@/services/student';
import { rtkQueryErrorLogger } from '@/utils/service';

const sagaMiddleware = createSagaMiddleware();

export const store = configureStore({
  reducer: {
    mapping: mappingSlice,
    confirmDialog: confirmDialogSlice,
    uploadDocument: uploadDocuments,
    user: userSlice,
    studentProfile: studentProfileSlice,
    sharedList: sharedListSlice,
    paymentInfo: paymentSlice,
    applicationData: applicationSlice,
    [studentApi.reducerPath]: studentApi.reducer,
    [applicationApi.reducerPath]: applicationApi.reducer,
    [documentUploadBaseApi.reducerPath]: documentUploadBaseApi.reducer,
    [geebeeRootBaseApiWithTags.reducerPath]: geebeeRootBaseApiWithTags.reducer,
    [documentDownloadBaseApi.reducerPath]: documentDownloadBaseApi.reducer,
    [bookmarkListBaseApi.reducerPath]: bookmarkListBaseApi.reducer,
    [authBaseApi.reducerPath]: authBaseApi.reducer,
    [seoRootBaseApiWithTags.reducerPath]: seoRootBaseApiWithTags.reducer,
    [studentDashboardApiWithTags.reducerPath]:
      studentDashboardApiWithTags.reducer,
    [cmsBaseApiWithTags.reducerPath]: cmsBaseApiWithTags.reducer,
    [onboardingFormBaseApi.reducerPath]: onboardingFormBaseApi.reducer,
    modals: modalsSlice,
    events: eventsSlice,
    courseFinderSlice: courseFinderSlice.reducer,
    joyride: joyRideSlice,
    cfStateSlice: cfStateSlice.reducer,
    mockTest: mockTestSlice,
    [rewardsBaseApi.reducerPath]: rewardsBaseApi.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        // Ignore these action types
        ignoredActions: [
          `confirmDialog/confirmDialog`,
          `uploadDocumentSlice/uploadApplicationDocuments`,
          `uploadDocuments/uploadDocuments`,
          `modals/updateModal`,
          `modals/removeModal`,
        ],
        // Ignore these paths in the state
        ignoredPaths: [
          `confirmDialog.onSubmit`,
          `payload.onModalClose`,
          `uploadDocument.onModalClose`,
          `modals.onCancel`,
          `modals.onProceed`,
        ],
      },
    }).concat(
      studentApi.middleware,
      applicationApi.middleware,
      geebeeRootBaseApiWithTags.middleware,
      studentDashboardApiWithTags.middleware,
      documentUploadBaseApi.middleware,
      documentDownloadBaseApi.middleware,
      authBaseApi.middleware,
      courseFinderApi.middleware,
      bookmarkListBaseApi.middleware,
      seoRootBaseApiWithTags.middleware,
      cmsBaseApiWithTags.middleware,
      onboardingFormBaseApi.middleware,
      rtkQueryErrorLogger,
      sagaMiddleware,
      rewardsBaseApi.middleware,
    ),
});
(store as any).sagaTask = sagaMiddleware.run(mockTestSaga);

setupListeners(store.dispatch);

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
