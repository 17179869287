export const enum Permissions {
  ADMIN = `ADMIN`,
  SUB_AGENT = `SUB_AGENT`,
  STUDENT = `STUDENT`,
}

export const enum FeatureList {
  SUB_AGENT_CTA = `SUB_AGENT_CTA`,
  ADD_APPLICATION_CTA = `ADD_APPLICATION_CTA`,
  ADD_STUDENT_CTA = `ADD_STUDENT_CTA`,
  GET_HELP_IN_PROFILE_SHORTLISTING_CTA = `GET_HELP_IN_PROFILE_SHORTLISTING_CTA`,
  DELETE_DOCUMENT = `DELETE_DOCUMENT`,
  BUSINESS_INSIGHTS = `BUSINESS_INSIGHTS`,
  QUICK_LINKS = `QUICK_LINKS`,
  PROFILE_DROPDOWN = `PROFILE_DROPDOWN`,
  APPLICATION_TYPE_DROPDOWN = `APPLICATION_TYPE_DROPDOWN`,
  VALUE_ADDED_SERVICES = `VALUE_ADDED_SERVICES`,
  SUB_AGENT_DROPDOWN_IN_ADD_STUDENT = `SUB_AGENT_DROPDOWN_IN_ADD_STUDENT`,
  SUB_AGENT_FILTER = `SUB_AGENT_FILTER`,
  UPCOMING_EVENTS = `UPCOMING_EVENTS`,
  STUDENTS_WITH_APPLICATIONS_INTKAE = `STUDENTS_WITH_APPLICATIONS_INTKAE`,
  RELATIONSHIP_MANAGER = `RELATIONSHIP_MANAGER`,
  COUNTRY_EXPERTS = `COUNTRY_EXPERTS`,
  AUIDT_LOGS = `AUDIT_LOGS`,
  REAWRDS_PANEL = `REAWRDS_PANEL`,
}

export const exludedFeaturesForPermission: Record<Permissions, FeatureList[]> =
  {
    ADMIN: [],
    SUB_AGENT: [
      FeatureList.SUB_AGENT_CTA,
      FeatureList.GET_HELP_IN_PROFILE_SHORTLISTING_CTA,
      // FeatureList.BUSINESS_INSIGHTS,
      FeatureList.QUICK_LINKS,
      FeatureList.PROFILE_DROPDOWN,
      FeatureList.APPLICATION_TYPE_DROPDOWN,
      FeatureList.VALUE_ADDED_SERVICES,
      FeatureList.SUB_AGENT_DROPDOWN_IN_ADD_STUDENT,
      FeatureList.SUB_AGENT_FILTER,
      FeatureList.STUDENTS_WITH_APPLICATIONS_INTKAE,
      FeatureList.QUICK_LINKS,
      FeatureList.COUNTRY_EXPERTS,
      FeatureList.RELATIONSHIP_MANAGER,
      FeatureList.UPCOMING_EVENTS,
      FeatureList.AUIDT_LOGS,
      FeatureList.REAWRDS_PANEL,
    ],
    STUDENT: [],
  };

export const disabledFeaturesForPermission: Record<Permissions, FeatureList[]> =
  {
    ADMIN: [],
    SUB_AGENT: [FeatureList.ADD_APPLICATION_CTA, FeatureList.ADD_STUDENT_CTA],
    STUDENT: [],
  };
