import * as Sentry from '@sentry/nextjs';

class SentryError extends Error {
  constructor(errMessage: any, data: any, type = `error`) {
    super(errMessage);
    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, SentryError);
    }

    Sentry.addBreadcrumb({
      category: `data`,
      message: errMessage,
      data: data,
      type: type,
      level: `error`,
    });
    Sentry.captureMessage(errMessage);
    Sentry.captureException(this);
  }
}

export default SentryError;
