import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

export const geebeeRootBaseApi = createApi({
  reducerPath: `geebeeBaseApi`,
  baseQuery: fetchBaseQuery({ baseUrl: `/api/geebeeRoute?path=` }),
  endpoints: () => ({}),
});

export const geebeeRootBaseApiWithTags = geebeeRootBaseApi.enhanceEndpoints({
  addTagTypes: [
    `documents`,
    `Student`,
    `Application`,
    `Login`,
    `Remarks`,
    `CountryAndState`,
    `applicationDocuments`,
    `Users`,
    `Shortlist`,
    `Deferral`,
    `SingleApplication`,
    `AuditLogs`,
    `ShortlistRequest`,
    `RequiredDocs`,
    `CheckDuplicate`,
    `Payment`,
    `preVisaStatus`,
    `pendingActions`,
    `SingleCspUser`,
    `StudentEducationForm`,
    `StudentWorkForm`,
    `StudentExamForm`,
    `RequiredApplicationDocs`,
    `StudentOnboardingForm`,
    `GteMasterDocs`,
    `GteApplicationDocs`,
    `GteCategories`,
    `ApplicationRemarks`,
    `ApplicationPendingTasks`,
    `StudentPendingTasks`,
    `AccomodationRequest`,
    `GICRequest`,
    `FetchPostOfferRequirements`,
    `FlywireRequest`,
    `AddSubAgent`,
    `UpdateSubAgent`,
    `ResetSubAgentPassword`,
    `validateRemove`,
    `StudentOnboardingFormPreference`,
    `GetSopDetailsFromAppId`,
    `GetSopDetailsFromRequestId`,
    `GICRequestRaised`,
    `LoansRequest`,
  ],
});
