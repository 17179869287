import { useEffect, useRef, useState } from 'react';

import {
  GBModalWrapper,
  GBTypography,
} from '@leapfinance/geebee-component-library';
import CloudUploadOutlinedIcon from '@mui/icons-material/CloudUploadOutlined';
import Cropper from 'cropperjs';
import { useSnackbar } from 'notistack';

import { useAppSelector } from '@/app/hooks';
import {
  cspLogoSupportedFormatsList,
  cspLogoUploadInstructionsList,
} from '@/components/CSPSettings/constants';
import { HandleCspLogoUploadParamConfig } from '@/components/CSPSettings/SettingsHeader/CspLogoUpdate/helpers';
import { MODAL_TYPES } from '@/components/Modals/GlobalModal';
import useModal from '@/components/Modals/useModal';
import { selectUserDetails } from '@/features/user/userSlice';
import { useUploadCspLogoMutation } from '@/services/documentUpload';
import { useLazyGetCspProfileQuery } from '@/services/student';
import { MaximumSizeForDocumentUploadEnum } from '@/utils/constants';

import 'cropperjs/dist/cropper.css';

export interface ILogoCropperModal {
  handleUploadConfig?: HandleCspLogoUploadParamConfig;
  source: 'REGISTRATION_FORM' | 'CSP_SETTINGS';
}

const LogoCropperModal = ({
  handleUploadConfig,
  source,
}: ILogoCropperModal) => {
  const [originalFormat, setOriginalFormat] = useState<string>(`image/png`);
  const [imageSrc, setImageSrc] = useState<string | null>(null);
  const { hideModal } = useModal();
  const fileUploadRef = useRef<HTMLInputElement | null>(null);
  const cropperRef = useRef<Cropper | null>(null);
  const imageRef = useRef<HTMLImageElement>(null);

  useEffect(() => {
    return () => {
      cropperRef.current?.destroy();
    };
  }, [imageSrc]);

  const handleClick = () => {
    if (fileUploadRef && fileUploadRef.current) {
      fileUploadRef.current.click();
    }
  };

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (file) {
      const url = URL.createObjectURL(file);
      setImageSrc(url);
      setOriginalFormat(file.type); // Store the original format
    }
  };

  const initializeCropper = () => {
    if (imageRef.current) {
      if (cropperRef.current) {
        cropperRef.current.destroy();
      }

      cropperRef.current = new Cropper(imageRef.current, {
        viewMode: 1,
        autoCropArea: 1,
        dragMode: `move`,
        aspectRatio: NaN, // Allows independent resizing
        cropBoxResizable: true,
        crop(event) {
          const cropper = cropperRef.current;
          const maxCropBoxWidth = 150;
          const maxCropBoxHeight = 40;

          const cropBoxData = cropper?.getCropBoxData();
          if (cropBoxData) {
            if (cropBoxData.width > maxCropBoxWidth) {
              cropper?.setCropBoxData({ width: maxCropBoxWidth });
            }
            if (cropBoxData.height > maxCropBoxHeight) {
              cropper?.setCropBoxData({ height: maxCropBoxHeight });
            }
          }
        },
      });
    }
  };

  const handleSaveImage = () => {
    if (cropperRef.current) {
      cropperRef.current.getCroppedCanvas().toBlob((blob) => {
        if (blob) {
          handleSaveCspLogo(blob);
        }
      }, `image/png`);
    }
  };

  const [triggerUploadCspLogo, { isLoading: isLogoUploading }] =
    useUploadCspLogoMutation();
  const cspProfileData = useAppSelector((state) => selectUserDetails(state));
  const { enqueueSnackbar } = useSnackbar();
  const [triggerGetCspProfileData] = useLazyGetCspProfileQuery();

  const handleSaveCspLogo = async (croppedBlob: Blob) => {
    if (!croppedBlob) return;
    const url = URL.createObjectURL(croppedBlob);
    const fileToUpload = new File([croppedBlob], `resized-image.png`, {
      type: `image/png`,
      lastModified: Date.now(),
    });
    if (fileToUpload?.size > MaximumSizeForDocumentUploadEnum[`2MB`]) {
      enqueueSnackbar(`Please resize the photo to fit within 2MB`);
      return;
    }
    if (source === `REGISTRATION_FORM`) {
      handleUploadConfig?.onUploadGetImageUrl(url);
      handleUploadConfig?.onUploadSuccess(fileToUpload);
      hideModal(MODAL_TYPES.LOGO_CROPPER_MODAL);
      return;
    }
    if (!cspProfileData?.cspId) return;
    try {
      const fileUploadResponse = await triggerUploadCspLogo({
        file: fileToUpload,
        cspId: cspProfileData?.cspId,
      }).unwrap();
      if (fileUploadResponse?.data && fileUploadResponse?.success) {
        enqueueSnackbar(`Logo updated successfully`, { variant: `success` });
        triggerGetCspProfileData();
        hideModal(MODAL_TYPES.LOGO_CROPPER_MODAL);
      } else {
        enqueueSnackbar(`Failed to update logo, please try again`, {
          variant: `error`,
        });
      }
    } catch (error) {
      console.log(`Error in uploading logo`, error);
      enqueueSnackbar(`Failed to update logo, please try again`, {
        variant: `error`,
      });
    }
  };

  return (
    <GBModalWrapper
      variant="small"
      headerProps={{
        title: `Upload Logo`,
        onClose: () => hideModal(MODAL_TYPES.LOGO_CROPPER_MODAL),
      }}
      footerProps={{
        primaryCta: {
          title: imageSrc ? `Update Logo` : `Continue`,
          buttonProps: {
            onClick: handleSaveImage,
            disabled: !imageSrc || isLogoUploading,
          },
        },
        secondaryCta: imageSrc
          ? {
              title: `Change Image`,
              buttonProps: {
                onClick: handleClick,
                disabled: isLogoUploading,
              },
            }
          : undefined,
      }}
    >
      <div className="p-4 flex flex-col gap-6">
        <input
          type="file"
          ref={fileUploadRef}
          className="hidden"
          onChange={handleFileChange}
          accept={cspLogoSupportedFormatsList?.join(`,`)}
        />
        {!imageSrc ? (
          <div
            className="flex flex-col justify-center items-center gap-1 border border-primary-500 border-dashed p-10 cursor-pointer bg-primary-50"
            onClick={handleClick}
          >
            <CloudUploadOutlinedIcon className="text-primary-500 w-10 h-10" />
            <GBTypography variant="cta5" className="text-primary-500">
              Upload File
            </GBTypography>
          </div>
        ) : (
          <div>
            {/* eslint-disable-next-line @next/next/no-img-element */}
            <img
              ref={imageRef}
              src={imageSrc}
              alt="Source"
              onLoad={initializeCropper}
              className="max-w-full"
              style={{
                display: `block`,
                maxWidth: `100%`,
              }}
            />
          </div>
        )}

        <div>
          {imageSrc ? (
            <GBTypography
              variant="sh2Semibold"
              className="text-gbTokens-grey-500 block text-center"
            >
              Drag and zoom the image accordingly to fit into the measurements
            </GBTypography>
          ) : (
            cspLogoUploadInstructionsList?.map(
              (instruction: string, index: number) => {
                return (
                  <GBTypography
                    key={`${instruction}-${index}`}
                    variant="sh2Regular"
                    className="text-gbTokens-grey-500 mt-1 block"
                  >
                    {index + 1}. {instruction}
                  </GBTypography>
                );
              },
            )
          )}
        </div>
      </div>
    </GBModalWrapper>
  );
};

export default LogoCropperModal;
