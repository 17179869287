import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

import { geebeeRootBaseApiWithTags } from '@/services/base/geebeeRoute';
import {
  GetCspDetailsFromDomainRequestType,
  GetCspDetailsFromDomainResponseType,
} from '@/types/Services';
import { transformResponse } from '@/utils/service';

export const authBaseApi = createApi({
  reducerPath: `loginBaseApi`,
  baseQuery: fetchBaseQuery({ baseUrl: `/api/auth` }),
  endpoints: (builder) => ({
    login: builder.mutation<
      { defaultPassword: boolean },
      { userName: string; password: string }
    >({
      query: (body) => ({
        url: `/login`,
        method: `POST`,
        body,
      }),
    }),
    changePassword: builder.mutation<
      { success: boolean },
      { userName: string; oldPassword: string; newPassword: string }
    >({
      query: (body) => ({
        url: `/changePassword`,
        method: `POST`,
        body,
      }),
    }),
    forgotPassword: builder.mutation<
      { success: boolean },
      { userName: string; appUrl: string }
    >({
      query: (body) => ({
        url: `/forgotPassword`,
        method: `POST`,
        body,
      }),
    }),
    resetPassword: builder.mutation<
      { success: boolean },
      { newPassword: string; token: string }
    >({
      query: (body) => ({
        url: `/resetPassword`,
        method: `POST`,
        body,
      }),
    }),
  }),
});

const loginBaseApi = geebeeRootBaseApiWithTags.injectEndpoints({
  endpoints: (builder) => ({
    getCspDetailsFromDomain: builder.query<
      GetCspDetailsFromDomainResponseType,
      GetCspDetailsFromDomainRequestType
    >({
      query: (payload) =>
        `/v1/onboarding-form/csp-details?csp-domain-from-url=${
          payload[`csp-domain-from-url`]
        }`,
      transformResponse,
    }),
  }),
});

export const {
  useLoginMutation,
  useChangePasswordMutation,
  useForgotPasswordMutation,
  useResetPasswordMutation,
} = authBaseApi;

export const {
  useGetCspDetailsFromDomainQuery,
  useLazyGetCspDetailsFromDomainQuery,
} = loginBaseApi;
