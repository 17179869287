import { isFalsy } from '@leapfinance/frontend-commons';
import {
  GBTypography,
  type OPTION_TYPE,
} from '@leapfinance/geebee-component-library';
import InfoOutlined from '@mui/icons-material/InfoOutlined';
import { type SelectData } from 'mui-rff';
import { NextRouter } from 'next/router';

import { RootState, store } from '@/app/store';
import { ApplicationStages } from '@/components/ApplicationSection/ApplicationFieldsData';
import EligiblityToolTip from '@/components/CF/Dashbaord/CfCoursesDashabord/FiltersGroup/Eligibility/EligiblityToolTip';
import { MODAL_TYPES } from '@/components/Modals/GlobalModal';
import { ModalDataType } from '@/features/modals/modalsSlice';
import getPartnerName from '@/permissions/getPartnerName';
import { definitions } from '@/types/schema';
import { UpdateExamInfoRequestType } from '@/types/Services';
import { SummaryCardTitle } from '@/types/summaryDashboard';

import { scrollToElementWithOffset } from './common';

export interface OptionType {
  label: string | number;
  value: string | number | null;
  isDisabled?: boolean;
}

export enum QueryParams {
  CREATE_APPLICATION = `create-application`,
  STUDENT_ID = `studentId`,
  SOURCE = `source`,
  IS_NEW = `isNew`,
  ACTIVE_DEGREE = `activeDegree`,
  IS_LOGIN_SUCCESS = `isLoginSuccess`,
  ACTION = `action`,
  IETLS_EXAM_CATEGORY = `category`,
}

export const educationalQualificationTypes: SelectData[] = [
  {
    label: `Masters`,
    value: `Masters Degree`,
  },
  {
    label: `Bachelors`,
    value: `Bachelors Degree`,
  },
  {
    label: `Diploma`,
    value: `Diploma`,
  },
  {
    label: `12th Standard`,
    value: `12th Standard`,
  },
  {
    label: `10th Standard`,
    value: `10th Standard`,
  },
];

export const profileSection: { [key: string]: string } = {
  personalInfo: `PERSONAL INFO`,
  academicInfo: `ACADEMIC INFO`,
  workExperience: `WORK EXPERIENCE`,
  tests: `TESTS`,
};
export const tabValue = {
  [`PROFILE`]: 0,
  [`APPLICATIONS`]: 2,
  [`DOCUMENTS`]: 3,
  [`SHORTLIST`]: 1,
  [`VISA`]: 4,
};
export const genderTypes: SelectData[] = [
  {
    label: `Female`,
    value: `FEMALE`,
  },
  {
    label: `Male`,
    value: `MALE`,
  },
  {
    label: `Others`,
    value: `OTHERS`,
  },
];

export const maritalStatusTypes: SelectData[] = [
  {
    label: `Single`,
    value: `SINGLE`,
  },
  {
    label: `Married`,
    value: `MARRIED`,
  },
];
export const employmentTypes: SelectData[] = [
  {
    label: `Full Time`,
    value: `FULL_TIME`,
  },
  {
    label: `Part Time`,
    value: `PART_TIME`,
  },
];
export const relationShipTypes: SelectData[] = [
  {
    label: `Father`,
    value: `father`,
  },
  {
    label: `Mother`,
    value: `mother`,
  },
  {
    label: `Sibling`,
    value: `sibling`,
  },
  {
    label: `Others`,
    value: `others`,
  },
];

export const applicationTypes: OPTION_TYPE[] = [
  {
    label: `Agent Portal`,
    value: `AGENT_PORTAL`,
  },
  {
    label: `Online Application`,
    value: `ONLINE_APPLICATION`,
  },
  {
    label: `Third Party Application`,
    value: `THIRD_PARTY_APPLICATION`,
  },
  {
    label: `Paper Based Application`,
    value: `PAPER_BASED_APPLICATION`,
  },
  {
    label: `Email`,
    value: `EMAIL`,
  },
  {
    label: `To be filed by Geebee`,
    value: `TO_BE_FILED_BY_GEEBEE`,
  },
];
export const paymentStatusTypes: SelectData[] = [
  {
    label: `Deposit Paid`,
    value: `DEPOSIT_PAID`,
  },
  {
    label: `Tuition Fee Paid`,
    value: `TUITION_FEE_PAID`,
  },
  {
    label: `Refunded`,
    value: `REFUNDED`,
  },
  {
    label: `Not Paid`,
    value: `NOT_PAID`,
  },
];
export const currencyTypeOptions: Array<OptionType> = [
  {
    label: `USD`,
    value: `USD`,
  },
  {
    label: `GBP`,
    value: `GBP`,
  },
  {
    label: `AUD`,
    value: `AUD`,
  },
  {
    label: `NZD`,
    value: `NZD`,
  },
  {
    label: `CAD`,
    value: `CAD`,
  },
  {
    label: `INR`,
    value: `INR`,
  },
  {
    label: `EURO`,
    value: `EURO`,
  },
  {
    label: `SGD`,
    value: `SGD`,
  },
];

export const paymentTypeOptions: Array<OptionType> = [
  {
    label: `TT`,
    value: `TT`,
  },
  {
    label: `Flywire`,
    value: `FLYWIRE`,
  },
  {
    label: `Bank Draft`,
    value: `BANK_DRAFT`,
  },
  {
    label: `Credit Card`,
    value: `CREDIT_CARD`,
  },
  {
    label: `Direct Fee Receipt`,
    value: `DIRECT_FEE_RECEIPT`,
  },
  {
    label: `Waiver Letter`,
    value: `WAIVER_LETTER`,
  },
  {
    label: `Western Union`,
    value: `WESTERN_UNION`,
  },
  {
    label: `Debit Card`,
    value: `DEBIT_CARD`,
  },
  {
    label: `Others`,
    value: `OTHERS`,
  },
];
export const maxLimitLeys: { [key: string]: number } = {
  BACHELORS_DEGREE: 2,
  MASTERS_DEGREE: 2,
  DIPLOMA: 2,
  PHD: 2,
  TENTH: 1,
  TWELFTH: 1,
  PG_DIPLOMA: 3,
};
export const gradeTotalOptions: { label: number; value: number }[] = [
  { label: 4, value: 4 },
  { label: 10, value: 10 },
  { label: 100, value: 100 },
];

export enum CommentStatus {
  IDLE = `IDLE`,
  UPLOADING = `UPLOADING`,
  FAILED = `FAILED`,
}

export const PaymentInfoIncludedStages: string[] = [
  `CONDITIONAL_ADMIT_RECEIVED`,
  `UNCONDITIONAL_ADMIT_RECEIVED`,
  `ADMIT_ACCEPTED`,
  `ADMIT_DECLINED`,
  `OFFER_REVOKED`,
  `APPLICATION_REJECTED`,
];

export const STAGE_LEVEL_SUMMARY_CARD_STYLING_CONFIG = (
  state: RootState,
): Record<string, Omit<SummaryCardTitle, 'titleText'>> => {
  return {
    'Actions Required': {
      tooltipText: `Please take actions on the below applications. Ideally these numbers should reach zero or as low as possible.`,
      titleTextColor: `text-error-600`,
      tooltipFillColor: `#8B2C2C`,
      titleBackgroundColor: `bg-error-100`,
    },
    'Pending At GeeBee': {
      tooltipText: `${getPartnerName(
        state,
      )}(HO) team will take action on the below applications. Ideally these numbers should reach zero or as low as possible. No action required on your end.`,
      titleTextColor: `text-secondary-600`,
      tooltipFillColor: `#8B2C2C`,
      titleBackgroundColor: `bg-warning-100`,
    },
    Others: {
      tooltipText: `No action is required on the below application or action is pending at the institute. We will update the application, once we have some communication from the institute.`,
      titleTextColor: `text-primary-600`,
      tooltipFillColor: `#8B2C2C`,
      titleBackgroundColor: `bg-primary-100`,
    },
  };
};
export const visaOnHoldReasonList: { label: string; value: string }[] = [
  { label: `Missing Documents`, value: `Missing Documents` },
  { label: `Document Quality Issue`, value: `Document Quality Issue` },
  { label: `Waiting for another Admit`, value: `Waiting for another Admit` },
];

export const visaApprovedTypeList: { label: string; value: string }[] = [
  { label: `Visa Approved in Principle`, value: `Visa Approved in Principle` },
  { label: `Passport request letter`, value: `Passport request letter` },
  { label: `Passport Stamped`, value: `Passport Stamped` },
];

export const visaDroppedReason: { label: string; value: string }[] = [
  {
    label: `Funds could not to be arranged`,
    value: `Funds could not to be arranged`,
  },
  {
    label: `Dropped the plan to study abroad`,
    value: `Dropped the plan to study abroad`,
  },
  { label: `Agent Change`, value: `Agent Change` },
  { label: `Applied themselves`, value: `Applied themselves` },
];

export const genderList = [
  {
    label: `Male`,
    value: `MALE`,
  },
  {
    label: `Female`,
    value: `FEMALE`,
  },
  {
    label: `Others`,
    value: `OTHERS`,
  },
] as any;
export const maritalStatusList = [
  {
    label: `Single`,
    value: `SINGLE`,
  },
  {
    label: `Married`,
    value: `MARRIED`,
  },
] as any;
export const editableStages = [
  `DRAFT`,
  `APPLICATION_RECEIVED`,
  `APPLICATION_ON_HOLD`,
];

export enum FILE_TYPE_ENUM {
  jpg = `jpg`,
  jpeg = `jpeg`,
  png = `png`,
  pdf = `pdf`,
}

export enum FILE_VIEWER_TYPE_ENUM {
  image = `image`,
  pdf = `pdf`,
  none = `none`,
}

export enum ZOOM_TYPE_ENUM {
  in = `in`,
  out = `out`,
  reset = `reset`,
}

export const PRE_LOGIN_ACCESS_PAGES = [
  `/forgotpwd`,
  `/resetpwd`,
  `/login`,
  `/student-login`,
  `/ielts`,
];

export const FILE_SIZE_35 = 35000000;

export enum COMPONENT_ID_ENUM {
  payments = `payments`,
  shortlists = `shortlists`,
  REQUIRED_DOCUMENT_SECTION = `required-document-section`,
  CONDITIONAL_SECTION = `conditional-section`,
  DEFERRAL_SECTION = `deferral-section`,
  DEFERRAL_PLACEHOLDER = `deferral-placeholder`,
  SHORTLIST_DOCUMENTS = `shortlist-documents`,
  CF_FILTER_SIDEBAR = `cf-filter-sidebar`,
  CF_FOOTER = `cf-footer`,
  APPLICATION_DETAILS_SECTION = `application-details-section`,
  APPLICATION_STATUS_INFO_SECTION = `application-status-info-section`,
  STUDENT_ELIGIBILTY_SECTION = `student-eligibility-section`,
  CF_APPLIED_FILTERS = `cf-applied-filters`,
  STUDENT_NEXT_STEPS = `student-next-steps`,
  GLOBAL_MODAL_WRAPPER_HEADER = `global-modal-wrapper-header`,
  GLOBAL_MODAL_WRAPPER_FOOTER = `global-modal-wrapper-footer`,
  CF_ELIGIBLITY_FILTER = `cf-eligiblity-filter`,
  CF_RESULT_SECTION = `cf-result-section`,
  NEW_DASHBOARD_FILTERS = `NEW_DASHBOARD_FILTERS`,
  NEW_DASHBOARD_PENDING_ACTIONS_SUMMARY = `NEW_DASHBOARD_PENDING_ACTIONS_SUMMARY`,
  NEW_DASHBOARD_PERFORMANCE_SECTION = `NEW_DASHBOARD_PERFORMANCE_SECTION`,
  NEW_DASHBOARD_NEWSLETTER_SECTION = `NEW_DASHBOARD_NEWSLETTER_SECTION`,
  NEW_DASHBOARD_QUICK_LINKS_SECTION = `NEW_DASHBOARD_QUICK_LINKS_SECTION`,
  CF_USP_FILTER_CHIPS_CONTAINER = `CF_USP_FILTER_CHIPS_CONTAINER`,
  MOBILE_CHAT_CONTAINER = `mobile-chat-container`,
  NO_RESULTS_REASON_SECTION = `no-result-reasons`,
  CF_GLOBAL_SEARCH_BANNER = `cf-global-search-banner`,
  TOP_FILTERS = `top-filters`,
  TOP_FILTERS_CONTAINER = `top-filters-container`,
  IELTS_SPEAKING_TEST_CHAT_CONTAINER_ID = `IELTS_SPEAKING_TEST_CHAT_CONTAINER_ID`,
  CF_STUDY_WHAT_CLEAR = `CF_STUDY_WHAT_CLEAR`,
  STUDENT_LEAD_STAGE = `STUDENT_LEAD_STAGE`,
  STUDENT_LOGIN_MOCK_TESTS = `STUDENT_LOGIN_MOCK_TESTS`,
  STUDENT_LOGIN_VIDEO_COURSES = `STUDENT_LOGIN_VIDEO_COURSES`,
  STUDENT_LOGIN_IELTS_COMPARISON = `STUDENT_LOGIN_IELTS_COMPARISON`,
  STUDENT_LOGIN_TESTIMONIALS = `STUDENT_LOGIN_TESTIMONIALS`,
}

export enum PRIMARY_ACTION_NAVIGATION_ENUM {
  MOVE_TO_VISA = `MOVE_TO_VISA`,
  MOVE_TO_PAYMENTS = `MOVE_TO_PAYMENTS`,
}

export enum LEAD_STAGES_ENUM {
  PAYMENT_DONE = `Payment Done`,
}

export const WINDOW_EVENT_ENUMS = {
  APPLICATION_LIST_REFETCH_EVENT: `APPLICATION_LIST_REFETCH_EVENT`,
  STUDENT_LIST_REFETCH_EVENT: `STUDENT_LIST_REFETCH_EVENT`,
};

export type ExamType = Exclude<
  Exclude<
    Exclude<UpdateExamInfoRequestType[`examInfoDetails`], undefined>[number],
    undefined
  >[`examType`],
  undefined
>;
export const ExamDocumentKeys: Record<
  ExamType,
  definitions[`DocumentDto`][`keyName`]
> = {
  TENTH: `TENTH_MARK_SHEET`,
  TWELFTH: `TWELFTH_MARK_SHEET`,
  DIPLOMA: `DIPLOMA_MARK_SHEET`,
  PG_DIPLOMA: `PGD_MARK_SHEET`,
  BACHELORS_DEGREE: `BACHELORS_DEGREE`,
  MASTERS_DEGREE: `MASTERS_DEGREE`,
  PHD: `PHD_MARK_SHEET`,
  RESUME: `RESUME`,
  WORK_EXPERIENCE: `WORK_EXPERIENCE`,
  IELTS: `IELTS_SCORE_CARD`,
  TOEFL: `TOEFL_SCORE_CARD`,
  PTE: `PTE_SCORE_CARD`,
  DUOLINGO: `DUOLINGO_SCORE_CARD`,
  GRE: `GRE_SCORE_CARD`,
  GMAT: `GMAT_SCORE_CARD`,
  MISCELLANEOUS: `COUNSELING_OTHER`,
  PASSPORT: `CONSOLIDATED_PASSPORT`,
  PAYMENT_PROOFS: `PAYMENT_ACKNOWLEDGEMENT_RECEIPT`,
};
export const STUDENT_PROFILE_OPTIONS = [
  { label: `Brilliant`, value: `BRILLIANT` },
  { label: `Average`, value: `AVERAGE` },
  { label: `Decent`, value: `DECENT` },
];

export enum STUDENT_PROFILE_ENUM {
  Brilliant = `BRILLIANT`,
  Average = `AVERAGE`,
  Decent = `DECENT`,
}

export const STUDENT_PROFILE_COLOR_MAP = {
  [STUDENT_PROFILE_ENUM.Average]: {
    studentProfilePillColorScheme: {
      textColor: `text-primary-500`,
      bgColor: `bg-primary-100`,
    },
  },
  [STUDENT_PROFILE_ENUM.Brilliant]: {
    studentProfilePillColorScheme: {
      textColor: `text-success-500`,
      bgColor: `bg-success-100`,
    },
  },
  [STUDENT_PROFILE_ENUM.Decent]: {
    studentProfilePillColorScheme: {
      textColor: `text-warning-500`,
      bgColor: `bg-warning-100`,
    },
  },
};
const primaryActionNextStageTrigger: Record<string, string> = {
  DRAFT: `APPLICATION_RECEIVED`,
  APPLICATION_ON_HOLD: `AGGREGATOR_ON_HOLD_RESOLVED`,
  APPLICATION_ON_HOLD_BY_UNIVERSITY: `UNIVERSITY_ON_HOLD_RESOLVED`,
};

const getNextStateToChange = (
  stage: string | undefined,
  nextValidStates?: definitions[`NextValidStatesDto`][],
) => {
  const stateToChange = stage && primaryActionNextStageTrigger[stage];
  return (
    nextValidStates?.find((state) => state?.labelKey === stateToChange) || {
      label: ``,
      labelKey: undefined,
    }
  );
};

export const getNavigationData = (
  stage: string,
  router: any,
  applicationId: number,
) => {
  switch (stage) {
    case PRIMARY_ACTION_NAVIGATION_ENUM.MOVE_TO_VISA:
      return () => {
        router?.push(`/profile/${router?.query?.studentId}?key=VISA`);
      };
    case PRIMARY_ACTION_NAVIGATION_ENUM.MOVE_TO_PAYMENTS:
      return () => {
        router?.push(
          `/profile/${router?.query?.studentId}?key=APPLICATIONS&applicationId=${applicationId}&isPaymentsEdit=true`,
          undefined,
          { shallow: true },
        );
        scrollToElementWithOffset(`#${COMPONENT_ID_ENUM.payments}`, 124);
      };
  }
};

export function getTimeLabelForTAT(
  minutes?: number | null,
  hours?: number | null,
): string {
  if (isFalsy(minutes) && isFalsy(hours)) {
    return ``;
  }

  if (minutes) {
    if (minutes < 30) {
      return `less than 30 minutes`;
    } else {
      return `less than an hour`;
    }
  } else {
    if (hours === 1) {
      return `1 hour`;
    } else {
      return `${hours} hours`;
    }
  }
}

const getSentToUniversityLabel = ({
  isPostOfferRequirement,
  sentToInstituteTimeInHours,
  sentToInstituteTimeInMinutes,
}: {
  isPostOfferRequirement?: boolean;
  sentToInstituteTimeInHours?: number;
  sentToInstituteTimeInMinutes?: number;
}) => {
  const time = sentToInstituteTimeInMinutes ?? sentToInstituteTimeInHours;
  const timeLabel = getTimeLabelForTAT(
    sentToInstituteTimeInMinutes,
    sentToInstituteTimeInHours,
  );

  const infoIcon = (
    <EligiblityToolTip
      title={`Turnaround time includes ${getPartnerName(
        store?.getState(),
      )}’s processing time in business hours (10 am to 6 pm) excluding Sunday.`}
      placement="bottom"
      disableFocusListener={false}
      disableHoverListener={false}
      disableTouchListener={false}
    >
      <InfoOutlined
        className="text-black text-sm cursor-pointer"
        fontSize="small"
      />
    </EligiblityToolTip>
  );
  if (time && isPostOfferRequirement) {
    return (
      <div className="flex flex-col gap-1">
        <GBTypography variant="sh2Semibold" className="text-primary-500  w-fit">
          Application sent to institution in {timeLabel}. We will update you
          with the response soon
          <span className="pl-[6px] ">{infoIcon}</span>
        </GBTypography>
        <GBTypography variant="caption1" className="text-grey-900">
          Please check and fulfill possible conditional requirements from the
          university.
        </GBTypography>
      </div>
    );
  } else if (time && !isPostOfferRequirement) {
    return (
      <div className="flex flex-col gap-1">
        <GBTypography variant="sh2Semibold" className="text-primary-500  w-fit">
          Application was processed and sent to institution in{` `}
          {timeLabel}
          <span className="pl-[6px] ">{infoIcon}</span>
        </GBTypography>
        <GBTypography variant="caption1" className="text-grey-900">
          We will update you with the response soon!
        </GBTypography>
      </div>
    );
  } else {
    return (
      <div className="flex flex-col gap-1">
        <GBTypography variant="sh2Semibold" className="text-primary-500 w-fit">
          Application sent to institution. We will update you with the response
          soon
        </GBTypography>
        {isPostOfferRequirement ? (
          <GBTypography variant="caption1" className="text-grey-900">
            Please check and fulfill possible conditional requirements from the
            university.
          </GBTypography>
        ) : null}
      </div>
    );
  }
};

export const getPrimaryActionData = (
  store: RootState,
  stage: string | undefined,
  onStatusChange: (
    status: definitions[`NextValidStatesDto`],
    applicationId: number,
    missingDocCount?: number,
  ) => void,
  router: NextRouter,
  showModal: ({ modalProps, modalType }: ModalDataType) => void,
  applicationId: number,
  studentName: string,
  nextValidStates?: definitions[`NextValidStatesDto`][],
  totalRequiredDocuments?: number | undefined,
  numberOfDocumentsUploaded?: number | undefined,
  leadStage?: string,
  handleGetStudentDashboardTrigger?: () => void,
  showNextActions?: (meta?: Record<string, string>) => void,
  showAdmitChances?: boolean,
  pendingConditionsToBeFulfilled?: boolean,
  sentToInstituteTimeInHours?: number,
  sentToInstituteTimeInMinutes?: number,
) => {
  const handleStateChange = () => {
    onStatusChange(
      getNextStateToChange(stage, nextValidStates),
      applicationId,
      Number(totalRequiredDocuments || 0) -
        Number(numberOfDocumentsUploaded || 0),
    );
  };

  const handleApplicationSTIChange = () => {
    showNextActions?.({
      renderState: `POST_OFFER_DOCS`,
    });
  };

  switch (stage) {
    case ApplicationStages.DRAFT:
      return {
        copy: `Do you wish to send this application to ${getPartnerName(
          store,
        )} for processing?`,
        ctaText: `Send`,
        ctaAction: handleStateChange,
      };
    case ApplicationStages.APPLICATION_RECEIVED:
      return {
        copy: `This application will be processed shortly and notification will be sent.`,
      };
    case ApplicationStages.DOCUMENT_VERIFIED:
      return {
        copy: `Details have been verified, will be filling the application soon`,
      };
    case ApplicationStages.APPLICATION_SENT_TO_UNIVERSITY:
      return {
        copy: getSentToUniversityLabel({
          isPostOfferRequirement:
            showAdmitChances && pendingConditionsToBeFulfilled,
          sentToInstituteTimeInHours,
          sentToInstituteTimeInMinutes,
        }),
        ...(showAdmitChances &&
          pendingConditionsToBeFulfilled && {
            ctaText: `Upload Documents`,
            ctaAction: handleApplicationSTIChange,
          }),
      };
    case ApplicationStages.APPLICATION_ON_HOLD:
      return {
        copy: `Do you want to resend the application for processing if you have already resolved the issues.`,
        ctaText: `Resend`,
        ctaAction: handleStateChange,
      };
    case ApplicationStages.APPLICATION_ON_HOLD_BY_UNIVERSITY:
      return {
        copy: `Do you want to resend the application for processing if you have already resolved the issues.`,
        ctaText: `Resend`,
        ctaAction: handleStateChange,
      };
    case ApplicationStages.AGGREGATOR_ON_HOLD_RESOLVED:
      return {
        copy: `The resolution that you have provided will be verified and processed shortly`,
      };
    case ApplicationStages.UNIVERSITY_ON_HOLD_RESOLVED:
      return {
        copy: `The resolution that you have provided will be verified and processed shortly`,
      };
    case ApplicationStages.CONDITIONAL_ADMIT_RECEIVED:
      return {
        copy:
          leadStage === LEAD_STAGES_ENUM.PAYMENT_DONE
            ? `Update Visa Status`
            : `Meet the conditions as mentioned in the offer letter`,
        ctaText:
          leadStage === LEAD_STAGES_ENUM.PAYMENT_DONE ? `Visa Status` : ``,
        ctaAction:
          leadStage === LEAD_STAGES_ENUM.PAYMENT_DONE
            ? getNavigationData(
                PRIMARY_ACTION_NAVIGATION_ENUM.MOVE_TO_VISA,
                router,
                applicationId,
              )
            : undefined,
      };
    case ApplicationStages.UNCONDITIONAL_ADMIT_RECEIVED:
      return {
        copy:
          leadStage === LEAD_STAGES_ENUM.PAYMENT_DONE
            ? `Update Visa Status`
            : `Make Payment for this application`,
        ctaText:
          leadStage === LEAD_STAGES_ENUM.PAYMENT_DONE
            ? `Visa Status`
            : `Mark payment done`,
        ctaAction:
          leadStage === LEAD_STAGES_ENUM.PAYMENT_DONE
            ? getNavigationData(
                PRIMARY_ACTION_NAVIGATION_ENUM.MOVE_TO_VISA,
                router,
                applicationId,
              )
            : getNavigationData(
                PRIMARY_ACTION_NAVIGATION_ENUM.MOVE_TO_PAYMENTS,
                router,
                applicationId,
              ),
      };
    case ApplicationStages.ADMIT_ACCEPTED:
      return {
        copy:
          leadStage === LEAD_STAGES_ENUM.PAYMENT_DONE
            ? `Update Visa Status`
            : `Make Payment for this application`,
        ctaText:
          leadStage === LEAD_STAGES_ENUM.PAYMENT_DONE
            ? `Visa Status`
            : `Mark payment done`,
        ctaAction:
          leadStage === LEAD_STAGES_ENUM.PAYMENT_DONE
            ? getNavigationData(
                PRIMARY_ACTION_NAVIGATION_ENUM.MOVE_TO_VISA,
                router,
                applicationId,
              )
            : getNavigationData(
                PRIMARY_ACTION_NAVIGATION_ENUM.MOVE_TO_PAYMENTS,
                router,
                applicationId,
              ),
      };
    case ApplicationStages.APPLICATION_REJECTED:
      return {
        copy:
          leadStage === LEAD_STAGES_ENUM.PAYMENT_DONE
            ? ``
            : `Raise another application for this student`,
        ctaText:
          leadStage === LEAD_STAGES_ENUM.PAYMENT_DONE ? `` : `Add application`,
        ctaAction:
          leadStage === LEAD_STAGES_ENUM.PAYMENT_DONE
            ? undefined
            : () => {
                showModal({
                  modalType: MODAL_TYPES.ADD_APPLICATION,
                  modalProps: {
                    student: {
                      studentId: Number(router?.query?.studentId),
                      studentName: studentName,
                    },
                    handleGetStudentDashboardTrigger: () => {
                      handleGetStudentDashboardTrigger?.();
                    },
                  },
                });
              },
      };
  }
};

export const shortlistRequestDocumentDetails = [
  { label: `Maths Evaluation`, value: `MATHS_EVALUATION` },
  { label: `GAP`, value: `GAP` },
  { label: `MOI`, value: `MOI` },
  { label: `Work Experience Certificate`, value: `WORK_EX` },
  { label: `Work Appointment Letter`, value: `WORK_APPOINTMENT_LETTER` },
  { label: `Pay Slip`, value: `PAY_SLIP` },
  { label: `Internship Certificate`, value: `INTERNSHIP_CERTIFICATE` },
  { label: `Ielts Score Card`, value: `IELTS_SCORE_CARD` },
  { label: `Toefl Score Card`, value: `TOEFL_SCORE_CARD` },
  { label: `Duolingo Score Card`, value: `DUOLINGO_SCORE_CARD` },
  { label: `GRE Score Card`, value: `GRE_SCORE_CARD` },
  { label: `GMAT Score Card`, value: `GMAT_SCORE_CARD` },
  { label: `PTE Score Card`, value: `PTE_SCORE_CARD` },
  { label: `Tenth Mark Sheet`, value: `TENTH_MARK_SHEET` },
  { label: `Tenth Certificate`, value: `TENTH_CERTIFICATE` },
  { label: `Twelfth Mark Sheet`, value: `TWELFTH_MARK_SHEET` },
  { label: `Twelfth Certificate`, value: `TWELFTH_CERTIFICATE` },
  { label: `Diploma Mark Sheet`, value: `DIPLOMA_MARK_SHEET` },
  { label: `Diploma Certificate`, value: `DIPLOMA_CERTIFICATE` },
  { label: `PGD Mark Sheet`, value: `PGD_MARK_SHEET` },
  { label: `PGD Certificate`, value: `PGD_CERTIFICATE` },
  { label: `UG DMC`, value: `UG_DMC` },
  {
    label: `UG Consolidated Mark Sheet`,
    value: `UG_CONSOLIDATED_MARK_SHEET`,
  },
  { label: `UG Passing Certificate`, value: `UG_PASSING_CERT` },
  { label: `UG First Sem Mark Sheet`, value: `UG_FIRST_SEM_MARK_SHEET` },
  { label: `UG Second Sem Mark Sheet`, value: `UG_SECOND_SEM_MARK_SHEET` },
  { label: `UG Third Sem Mark Sheet`, value: `UG_THIRD_SEM_MARK_SHEET` },
  { label: `UG Fourth Sem Mark Sheet`, value: `UG_FOURTH_SEM_MARK_SHEET` },
  { label: `UG Fifth Sem Mark Sheet`, value: `UG_FIFTH_SEM_MARK_SHEET` },
  { label: `UG Six Sem Mark Sheet`, value: `UG_SIX_SEM_MARK_SHEET` },
  { label: `UG Seven Sem Mark Sheet`, value: `UG_SEVEN_SEM_MARK_SHEET` },
  { label: `UG Eight Sem Mark Sheet`, value: `UG_EIGHT_SEM_MARK_SHEET` },
  { label: `UG Ninth Sem Mark Sheet`, value: `UG_NINTH_SEM_MARK_SHEET` },
  { label: `UG Tenth Sem Mark Sheet`, value: `UG_TENTH_SEM_MARK_SHEET` },
  {
    label: `UG Credit Requirement and Grading System`,
    value: `UG_CREDIT_AND_GRADING_SYSTEM`,
  },
  { label: `UG Backlog Certificate`, value: `UG_BACKLOG_CERTIFICATE` },
  {
    label: `Provisional Degree Certificate`,
    value: `PROVISIONAL_DEGREE_CERTIFICATE`,
  },
  { label: `Final Degree Certificate`, value: `FINAL_DEGREE_CERTIFICATE` },
  { label: `Backlog Letter`, value: `BACKLOG_LETTER` },
  { label: `Transcript Graduation`, value: `TRANSCRIPT_GRADUATION` },
  { label: `PG DMC`, value: `PG_DMC` },
  {
    label: `PG Consolidated Mark Sheet`,
    value: `PG_CONSOLIDATED_MARK_SHEET`,
  },
  { label: `PG Passing Cert`, value: `PG_PASSING_CERT` },
  { label: `PG First Sem Mark Sheet`, value: `PG_FIRST_SEM_MARK_SHEET` },
  { label: `PG Second Sem Mark Sheet`, value: `PG_SECOND_SEM_MARK_SHEET` },
  { label: `PG Third Sem Mark Sheet`, value: `PG_THIRD_SEM_MARK_SHEET` },
  { label: `PG Fourth Sem Mark Sheet`, value: `PG_FOURTH_SEM_MARK_SHEET` },
  { label: `PG Fifth Sem Mark Sheet`, value: `PG_FIFTH_SEM_MARK_SHEET` },
  { label: `PG Six Sem Mark Sheet`, value: `PG_SIX_SEM_MARK_SHEET` },
  {
    label: `PG Credit Requirement and Grading System`,
    value: `PG_CREDIT_AND_GRADING_SYSTEM`,
  },
  { label: `PG Backlog Certificate`, value: `PG_BACKLOG_CERTIFICATE` },
  {
    label: `Transcript Higher Education`,
    value: `TRANSCRIPT_HIGHER_EDUCATION`,
  },
  { label: `PHD Mark Sheet`, value: `PHD_MARK_SHEET` },
  { label: `PHD Certificate`, value: `PHD_CERTIFICATE` },
  { label: `Resume`, value: `RESUME` },
  { label: `Passport Front`, value: `PASSPORT_FRONT` },
  { label: `Passport Back`, value: `PASSPORT_BACK` },
  { label: `Consolidated Passport`, value: `CONSOLIDATED_PASSPORT` },
  { label: `Counseling Other`, value: `COUNSELING_OTHER` },
];

export enum REMARK_CATEGORIES {
  DOCUMENTS_UPLOADED = `DOCUMENTS_UPLOADED`,
  LOGIN_CREDENTIALS = `LOGIN_CREDENTIALS`,
  CREDIT_CARD_DETAILS = `CREDIT_CARD_DETAILS`,
  EMERGENCY_CONTACT_DETAILS = `EMERGENCY_CONTACT_DETAILS`,
  ALTERNATE_PROGRAM_CHOICE = `ALTERNATE_PROGRAM_CHOICE`,
  APPLICATION_DROP_CONFIRMATION = `APPLICATION_DROP_CONFIRMATION`,
  DEFERMENT = `DEFERMENT`,
  PAID_TUITION_FEES = `PAID_TUITION_FEES`,
  PAYMENT_PROOF_UPLOADED = `PAYMENT_PROOF_UPLOADED`,
  STUDENT_ID_CONFIRMATION = `STUDENT_ID_CONFIRMATION`,
  ADMIT_DECLINED_CONFIRMATION = `ADMIT_DECLINED_CONFIRMATION`,
  VISA_CONFIRMATION = `VISA_CONFIRMATION`,
  OFFER_ACCEPTANCE_CONFIRMATION = `OFFER_ACCEPTANCE_CONFIRMATION`,
  ALTERNATE_INTAKE = `ALTERNATE_INTAKE`,
  DECISION_UPDATE_REQUIRED = `DECISION_UPDATE_REQUIRED`,
  PRIORITY_SUBMISSION = `PRIORITY_SUBMISSION`,
  SYSTEM_GENERATED_REMARKS = `SYSTEM_GENERATED_REMARKS`,
}

export enum ROUTER_ACTION_TRIGGERS {
  DOCUMENT_UPLOAD = `document-upload`,
  COMPLETE_PROFILE = `complete-profile`,
}

export enum DOCUMENT_UPLOAD_ACTIONS {
  UPLOADED = `UPLOADED`,
  PENDING = `PENDING`,
}

export enum StudentEligiblityScoreTestTypes {
  backlog = `Backlogs`,
  duoLingoSubjectMin = `Duolingo Subject Minimum`,
  duoLingoTotal = `DUOLINGO`,
  gapInStudiesAccepted = `Gap In Studies Accepted`,
  ieltsSubjectMin = `IELTS Subject Minimum`,
  ieltsTotal = `IELTS`,
  pteSubjectMin = `PTE Subject Minimum`,
  pteTotal = `PTE`,
  toeflSubjectMin = `TOEFL Subject Minimum`,
  toeflTotal = `TOEFL`,
  twelfthScore10 = `Twelfth Score(out of 10)`,
  twelfthScore100 = `Twelfth Score(out of 100)`,
  ugScore10 = `Undergraduate (out of 10)`,
  ugScore100 = `Undergraduate (out of 100)`,
  ugScore4 = `Undergraduate (out of 4)`,
  eligibilityDataSource = ``,
}

export enum ActionSourceMapper {
  APP_MODAL = `app_modal`,
  EMAIL = `email`,
}

export enum ProgramStatusTypes {
  OPEN = `OPEN`,
  CLOSED = `CLOSED`,
  NOT_YET_COMMENCED = `NOT_YET_COMMENCED`,
  UNKNOWN = `UNKNOWN`,
  YET_TO_OPEN = `YET_TO_OPEN`,
  LIKELY_OPEN = `LIKELY_OPEN`,
  MAYBE_CLOSED = `MAYBE_CLOSED`,
}

export const handleRedirectionToCourseFinder = (
  studentId: number,
  studentName: string,
  country?: string,
  intake?: string,
  courseName?: string,
  degree?: string,
  filterKeyName?: string,
  showOpenOnly?: boolean,
) => {
  if (window) {
    window?.open(
      `/course-finder?studentId=${studentId}&name=${studentName}&${filterKeyName}=${JSON.stringify(
        {
          country,
          intake,
          courseName,
          degree,
          showOpenOnly,
        },
      )}`,
    );
  }
};

export enum DocumentVisibilityStatusTypes {
  VISIBLE = `visible`,
  HIDDEN = `hidden`,
}

export const DISABLED_CONDITIONAL_REASONS = [
  `BANK_STATEMENT`,
  `FINANCIAL_DOCUMENT`,
];

export const convertDaysToWeeks = (days: number | undefined) => {
  if (!days || Number.isNaN(days) || Number(days) < 0) {
    return;
  }
  if (days < 7) {
    if (days === 1) {
      return `1 Day`;
    }
    return `${days} Days`;
  }
  const convertedWeeks = Math.round(days / 7);

  if (convertedWeeks === 1) {
    return `1 Week`;
  }
  return `${convertedWeeks} Weeks`;
};

export enum RemarksInteractionTypes {
  TEXT = `TEXT`,
  SELECT = `SELECT`,
  QUESTIONNAIRE = `QUESTIONNAIRE`,
}

export enum MaximumSizeForDocumentUploadEnum {
  '2MB' = 2000000,
}

export const acceptedAdmitChancesList = [`Safe`, `Moderate`];

export enum ModalOpenWithQueryParamValue {
  OPEN_GIC_SUGGESTION_MODAL = `openGICSuggestion`,
}

export const microsoftDocuments = `.doc,.docx,.xml,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document`;
export const parseDateForNewDesignSystemComponents = (value: any) => {
  if (value) {
    return new Date(value);
  }
};

export const formatDateForNewDesignSystemComponents = (value: any) => {
  if (value) {
    const inputDate = new Date(value);
    const transformedDate = inputDate.toISOString().split(`T`)[0];
    return transformedDate;
  }
};
