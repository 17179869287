import {
  createApi,
  FetchArgs,
  fetchBaseQuery,
} from '@reduxjs/toolkit/dist/query/react';

import {
  ExecuteReqwardRequestType,
  ExecuteReqwardResponseType,
  GetRewardDataResponseType,
  GetRewardProdcutsListResponseType,
  RewardsTransactionRequestType,
  RewardsTransactionResponseType,
} from '@/types/Services';

import { transformResponse } from './utils';

export const rewardsBaseApi = createApi({
  reducerPath: `rewardsBaseApi`,
  baseQuery: fetchBaseQuery({ baseUrl: `/api/geebeeRoute?path=/rewards` }),
  endpoints: () => ({}),
});

export const rewardsBaseApiWithTags = rewardsBaseApi.enhanceEndpoints({
  addTagTypes: [`Rewards`],
});

export const rewardsApi = rewardsBaseApiWithTags.injectEndpoints({
  endpoints: (builder) => ({
    getRewardsProductsList: builder.query<
      GetRewardProdcutsListResponseType,
      void
    >({
      query: () => `/order/products/list`,
      transformResponse,
    }),
    getRewardsData: builder.query<GetRewardDataResponseType, void>({
      query: () => `/`,
      transformResponse,
      providesTags: [`Rewards`],
      keepUnusedDataFor: 0,
    }),
    placeOrder: builder.mutation<
      ExecuteReqwardResponseType,
      ExecuteReqwardRequestType
    >({
      query: (body) => {
        return {
          url: `/order/execute`,
          method: `POST`,
          body,
        } as FetchArgs;
      },
      transformResponse,
      // invalidatesTags: ['Rewards'],
    }),
    getCoinsTransactions: builder.mutation<
      RewardsTransactionResponseType,
      RewardsTransactionRequestType
    >({
      query: (body) => {
        return { url: `/transactions`, method: `POST`, body } as FetchArgs;
      },
      transformResponse,
    }),
  }),
});

export const {
  useGetRewardsProductsListQuery,
  useGetRewardsDataQuery,
  usePlaceOrderMutation,
  useGetCoinsTransactionsMutation,
} = rewardsApi;
