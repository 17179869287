import React, { useEffect } from 'react';

import { useRouter } from 'next/router';

import { useAppSelector } from '@/app/hooks';
import { store } from '@/app/store';
import { selectUserDetails } from '@/features/user/userSlice';
import useCommissionStructureModal from '@/hooks/useCommissionStructureModal';
import { Permissions } from '@/permissions/featuresList';
import { useGetMappingQuery } from '@/services/config';
import {
  useGetSharedListsQuery,
  useLazyGetCspProfileQuery,
} from '@/services/student';
import { getElement } from '@/tours/helpers';

import NewsletterAnnouncementWrapper from './NewletterAnnouncementWrapper';
interface LayoutProps {
  children: React.ReactNode;
}

const hideChatButtonFor = [`/manage-student-registration-form`];

const Layout = (props: LayoutProps) => {
  // just calling the api here, on fullfiling result will automatically be store in store
  useGetSharedListsQuery();
  useGetMappingQuery();
  useCommissionStructureModal();
  const userInfo = useAppSelector((state) => selectUserDetails(state));
  const [profileTrigger] = useLazyGetCspProfileQuery();
  useEffect(() => {
    if (Object.keys(userInfo || {}).length === 0) profileTrigger();
  }, [userInfo]);
  const router = useRouter();

  const isSubAgent =
    store.getState().user?.userConfig?.permissions?.[0] ===
    Permissions.SUB_AGENT;

  const isStudentUser =
    store.getState().user?.userConfig?.permissions?.[0] === Permissions.STUDENT;

  useEffect(() => {
    if (isSubAgent || hideChatButtonFor?.includes(router.route)) {
      getElement(`#mobile-chat-container`, 10).then((element) => {
        if (!element) return;
        (element as any).style.display = `none`;
      });
    }
  }, [isSubAgent, router]);

  if (isStudentUser && !router?.route?.startsWith(`/test-prep/`)) {
    router?.replace(`/test-prep/mock-test`);
    return null;
  }

  return (
    <NewsletterAnnouncementWrapper>
      {props.children}
    </NewsletterAnnouncementWrapper>
  );
};

export default Layout;
