import { type IResetPasswordProps } from '@leapfinance/frontend-commons/components/geebee/subAgent/ResetPassword';
import { type KeyValueDisplayModalProps } from '@leapfinance/frontend-commons/components/KeyValueDisplayModal';
import { type NoResultsProps } from '@leapfinance/frontend-commons/components/NoResults';
import { createSlice, type PayloadAction } from '@reduxjs/toolkit';

import type { RootState } from '@/app/store';
import { INextActionDrawerProps } from '@/components/ApplicationSection/NextActions/NextActionDrawer';
import { DeferralHistoryModalProps } from '@/components/Deferral/DeferralHistoryModal';
import { IFileViewerProps } from '@/components/FileViewer';
import { AddCourseToShortlistProps } from '@/components/Modals/AddCourseToShortlist';
import { IAddRemarkModal } from '@/components/Modals/AddRemarkModal';
import { IAddStudentModalProps } from '@/components/Modals/AddStudentModal';
import { IAddToBookmark } from '@/components/Modals/AddToBookmark';
import { IAddApplication } from '@/components/Modals/Application/AddApplication';
import { IEligibleCoursesModalProps } from '@/components/Modals/Application/EligibleCoursesModal';
import { ICreateBookmarkListModalProps } from '@/components/Modals/CreateBookmarkListModal';
import { VASDrawerProps } from '@/components/Modals/Drawers/VASDrawer';
import { IEmailConfirmationModal } from '@/components/Modals/EmailConfirmationModal';
import { EnableMicModalProps } from '@/components/Modals/EnableMicModal';
import { MODAL_TYPES } from '@/components/Modals/GlobalModal';
import { INewsletterFeatureAnnouncementProps } from '@/components/Modals/NewsletterFeatureAnnouncement';
import { IPaymentVerificationReminderModalProps } from '@/components/Modals/PaymentVerificationReminderModal';
import { IProfileUnsavedChangesModal } from '@/components/Modals/ProfileUnsavedChangesModal';
import { IRequestVasCallbackModalProps } from '@/components/Modals/RequestVasCallbackModal';
import { IRequiredDocModal } from '@/components/Modals/RequiredDocModal';
import { ResetPasswordSuccessModalProps } from '@/components/Modals/RestPasswordSuccessModal';
import { RetakeSpeakingTestModalProps } from '@/components/Modals/RetakeSpeakingTestModal';
import { IShortlistInformationAndPreferenceModal } from '@/components/Modals/ShortlistInformationAndPreferenceModal';
import { IShortlistRatingProps } from '@/components/Modals/ShortlistRatingModal';
import { ShortlistRecipientModalProps } from '@/components/Modals/ShortlistRecipientModal';
import { ISimilarCourseModalProps } from '@/components/Modals/SimilarCourseModal';
import { IStatusChangeModals } from '@/components/Modals/StatusChangeModals';
import { ConfirmDeleteExistingProps } from '@/components/Modals/StudentOnboarding/ConfirmDeleteExistingModal';
import { IStudentOnboardingFormPublishedFeedback } from '@/components/Modals/StudentOnboarding/FormPublishedFeedback/FormPublishedFeedback';
import { IStudentOnboardingFormPreviewProps } from '@/components/Modals/StudentOnboarding/StudentOnboardingFormPreview';
import { IStudentProfileOnboardingModal } from '@/components/Modals/StudentProfileOnboardingModal';
import { TatPerceptionModalProps } from '@/components/Modals/TatPerceptionModal';
import { UpdateInfoModalProps } from '@/components/Modals/UpdateInfoModal';
import { GICMarkSuggestionModalProps } from '@/components/Modals/vas/GICMarkSuggestionModal';
import { PartnerProfileModalProps } from '@/components/PartnerContacts/PartnerProfileModal';
import { ILogoCropperModal } from '@/components/StudentOnboarding/PublishedFormView/LogoCropperModal';
import { IAddSugAgentFormProps } from '@/components/SubAgent/AddSugAgentForm';

import { ActionCenterProps } from '../../components/ActionCenter/ActionCenter';

type EmaiConfirmationModal = {
  modalType: MODAL_TYPES.EMAIL_CONFIRMATION | null;
  modalProps: IEmailConfirmationModal | null;
};

type FileViewerType = {
  modalType: MODAL_TYPES.FILE_VIEWER | null;
  modalProps: IFileViewerProps | null;
};

type AddCourseModalType = {
  modalType: MODAL_TYPES.ADD_APPLICATION | null;
  modalProps: IAddApplication | null;
};

type AddStudentType = {
  modalType: MODAL_TYPES.ADD_STUDENT | null;
  modalProps: IAddStudentModalProps | null;
};

type ShortlistInformationAndPreferenceModalType = {
  modalType: MODAL_TYPES.SHORTLIST_INFORMATION_PREFERENCE | null;
  modalProps: IShortlistInformationAndPreferenceModal | null;
};

type PreVisaInfoType = {
  modalType: MODAL_TYPES.PRE_VISA_INFO | null;
  modalProps: KeyValueDisplayModalProps | null;
};

type MandatoryDocType = {
  modalType: MODAL_TYPES.MANDATORY_DOC_INFO | null;
  modalProps: IRequiredDocModal | null;
};

type NoResultsModal = {
  modalType: MODAL_TYPES.NO_RESULTS | null;
  modalProps: NoResultsProps | null;
};

type ActionCenterModal = {
  modalType: MODAL_TYPES.ACTION_CENTER | null;
  modalProps: ActionCenterProps | null;
};

type StudentProfileOnBoardingModal = {
  modalType: MODAL_TYPES.STUDENT_PROFILE_ONBOARDING | null;
  modalProps: IStudentProfileOnboardingModal | null;
};

type AddToBookmarksModalType = {
  modalType: MODAL_TYPES.ADD_BOOKMARK | null;
  modalProps: IAddToBookmark | null;
};

type CreateBookmarkListModal = {
  modalType: MODAL_TYPES.CREATE_BOOKMARK_LIST_MODAL | null;
  modalProps: ICreateBookmarkListModalProps | null;
};
type PaymentVerificationReminderModal = {
  modalType: MODAL_TYPES.PAYMENT_VERIFICATION_REMINDER | null;
  modalProps: IPaymentVerificationReminderModalProps | null;
};

type AddRemarkModal = {
  modalType: MODAL_TYPES.ADD_REMARK | null;
  modalProps: IAddRemarkModal | null;
};

type NextActionsModal = {
  modalType: MODAL_TYPES.NEXT_ACTIONS | null;
  modalProps: INextActionDrawerProps | null;
};

type StatusChangeModal = {
  modalType: MODAL_TYPES.STATUS_CHANGE | null;
  modalProps: IStatusChangeModals | null;
};

type ProfileUnSaveChangesModal = {
  modalType: MODAL_TYPES.PROFILE_UNSAVE_MODAL;
  modalProps: IProfileUnsavedChangesModal;
};

type UpdateInfoModal = {
  modalType: MODAL_TYPES.UPDATE_INFO_MODAL;
  modalProps: UpdateInfoModalProps;
};

type ShortlistRatingModal = {
  modalType: MODAL_TYPES.SHORTLIST_RATING_MODAL;
  modalProps: IShortlistRatingProps | null;
};

type SimilarCourseModal = {
  modalType: MODAL_TYPES.SIMILAR_COURSE_MODAL;
  modalProps: ISimilarCourseModalProps;
};

type StudentExamDetailsModal = {
  modalType: MODAL_TYPES.STUDENT_EXAM_DETAILS_MODAL;
  modalProps: undefined;
};

type UpdateCspLogoModal = {
  modalType: MODAL_TYPES.UPDATE_CSP_LOGO_MODAL;
  modalProps: undefined;
};

type EligibleCoursesModal = {
  modalType: MODAL_TYPES.ELIGIBLE_COURSES_MODAL;
  modalProps: IEligibleCoursesModalProps;
};

type VasRequestCallbackModal = {
  modalType: MODAL_TYPES.REQUEST_VAS_CALLBACK;
  modalProps: IRequestVasCallbackModalProps;
};

type NewsletterAnnouncement = {
  modalType: MODAL_TYPES.NEWSLETTER_ANNOUNCEMENT;
  modalProps: INewsletterFeatureAnnouncementProps;
};

type VASDrawer = {
  modalType: MODAL_TYPES.VAS_DRAWER;
  modalProps: VASDrawerProps;
  variant: 'drawer';
};

type GTEModalType = {
  modalType: MODAL_TYPES.GTE_PROCESS | null;
  modalProps: null;
};

type AddCourseToShortlistModalType = {
  modalType: MODAL_TYPES.ADD_COURSE_TO_SHORTLIST | null;
  modalProps: AddCourseToShortlistProps;
};

type AddSubAgentModalType = {
  modalType: MODAL_TYPES.ADD_SUB_AGENT_FORM | null;
  modalProps: IAddSugAgentFormProps;
};
type DeferralHistoryModalType = {
  modalType: MODAL_TYPES.DEFFERAL_HISTORY_MODAL | null;
  modalProps: DeferralHistoryModalProps;
};

type StudentOnboardingConfirmDeleteExistingModalType = {
  modalType: MODAL_TYPES.CONFIRM_DELETE_EXISTING_MODAL | null;
  modalProps: ConfirmDeleteExistingProps;
};

type StudentOnboardingFormPublishedFeedbackModalType = {
  modalType: MODAL_TYPES.STUDENT_ONBOARDING_PUBLISHED_FORM_FEEDBACK;
  modalProps: IStudentOnboardingFormPublishedFeedback;
};

type StudentOnboardingFormPreviewModalType = {
  modalType: MODAL_TYPES.STUDENT_ONBOARDING_FORM_PREVIEW;
  modalProps: IStudentOnboardingFormPreviewProps;
};
type ResetPasswordModalType = {
  modalType: MODAL_TYPES.RESET_PASSWORD_MODAL | null;
  modalProps: IResetPasswordProps;
};
type ResetPasswordSuccessModalType = {
  modalType: MODAL_TYPES.RESET_PASSWORD_SUCCESS_MODAL | null;
  modalProps: ResetPasswordSuccessModalProps;
};
type GICMarkSuggestionModalType = {
  modalType: MODAL_TYPES.GIC_MARK_SUGGESTION_MODAL;
  modalProps: GICMarkSuggestionModalProps;
};

type EnableMicModalPropsType = {
  modalType: MODAL_TYPES.ENABLE_MIC_MODAL | null;
  modalProps: EnableMicModalProps | null;
};

type RetakeSpeakingTestModalPropsType = {
  modalType: MODAL_TYPES.RETAKE_SPEAKING_TEST_MODAL | null;
  modalProps: RetakeSpeakingTestModalProps;
};

type ShortlistRecipientModalPropsType = {
  modalType: MODAL_TYPES.SHORTLIST_RECIPIENT_LIST_MODAL | null;
  modalProps: ShortlistRecipientModalProps;
};

type LogoCropperModalProps = {
  modalType: MODAL_TYPES.LOGO_CROPPER_MODAL | null;
  modalProps: ILogoCropperModal;
};

type TatPerceptionModalType = {
  modalType: MODAL_TYPES.TAT_PERCEPTION_MODAL;
  modalProps: TatPerceptionModalProps;
};

type PartnerProfileModalType = {
  modalType: MODAL_TYPES.PARTNER_PROFILE_MODAL;
  modalProps: PartnerProfileModalProps;
};

type LoansRequestUpsellModalType = {
  modalType: MODAL_TYPES.LOANS_REQUEST_UPSELL;
  modalProps: null | undefined;
};
export type ModalDataType =
  | ActionCenterModal
  | AddCourseModalType
  | AddStudentType
  | FileViewerType
  | MandatoryDocType
  | NoResultsModal
  | PreVisaInfoType
  | ShortlistInformationAndPreferenceModalType
  | StudentProfileOnBoardingModal
  | PaymentVerificationReminderModal
  | AddRemarkModal
  | NextActionsModal
  | StatusChangeModal
  | AddToBookmarksModalType
  | CreateBookmarkListModal
  | EmaiConfirmationModal
  | ProfileUnSaveChangesModal
  | UpdateInfoModal
  | ShortlistRatingModal
  | SimilarCourseModal
  | StudentExamDetailsModal
  | UpdateCspLogoModal
  | EligibleCoursesModal
  | VasRequestCallbackModal
  | NewsletterAnnouncement
  | VASDrawer
  | GTEModalType
  | AddCourseToShortlistModalType
  | DeferralHistoryModalType
  | StudentOnboardingConfirmDeleteExistingModalType
  | StudentOnboardingFormPublishedFeedbackModalType
  | StudentOnboardingFormPreviewModalType
  | AddSubAgentModalType
  | ResetPasswordModalType
  | ResetPasswordSuccessModalType
  | GICMarkSuggestionModalType
  | EnableMicModalPropsType
  | RetakeSpeakingTestModalPropsType
  | ShortlistRecipientModalPropsType
  | LogoCropperModalProps
  | TatPerceptionModalType
  | PartnerProfileModalType
  | LoansRequestUpsellModalType;

export type ModalType = ModalDataType[];

const initialState: ModalType = [];

export const modalsSlice = createSlice({
  name: `modals`,
  initialState,
  reducers: {
    updateModal: (state, action: PayloadAction<ModalDataType>) => {
      const newModal = [...state];
      const findModalIndex = state.findIndex(
        (eachModal) => eachModal.modalType === action.payload.modalType,
      );
      if (findModalIndex >= 0) {
        newModal.splice(findModalIndex, 1, action.payload as any);
      } else {
        newModal.push(action.payload as any);
      }
      return newModal;
    },
    removeModal: (state, action: PayloadAction<MODAL_TYPES | undefined>) => {
      if (!action.payload || typeof action.payload !== `string`) {
        return [];
      }
      const newModal = state.filter(
        (eachModal) => eachModal.modalType !== action.payload,
      );

      return newModal;
    },
  },
});
export const selectModal = (state: RootState) => {
  return state.modals || [];
};
export const { updateModal, removeModal } = modalsSlice.actions;

export default modalsSlice.reducer;
